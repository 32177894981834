import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Router from 'vue-router'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    children:[
      {
        path: '/home',
        name: 'home',
        component: () => import( '../views/common/home.vue'),
        meta: {
          title: '菜单管理',
          isFresh: false,
        }
      },{
        path: '/admin/menu',
        name: 'admin/menu',
        component: () => import( '../views/admin/menu.vue'),
        meta: {
          title: '菜单管理',
          isFresh: false,
        }
      }, {
        path: '/admin/permission',
        name: 'admin/permission',
        component: () => import( '../views/admin/permission.vue'),
        meta: {
          title: '权限管理',
          isFresh: false,
        }
      },  {
        path: '/admin/role',
        name: 'admin/role',
        component: () => import( '../views/admin/role/role.vue'),
        meta: {
          title: '角色管理',
          isFresh: false,
        }
      }, {
        path: '/admin/admin',
        name: 'admin/admin',
        component: () => import( '../views/admin/admin/admin.vue'),
        meta: {
          title: '管理员管理',
          isFresh: false,
        }
      },{
        path: '/user',
        name: 'user',
        component: () => import( '../views/user/user.vue'),
        meta: {
          title: '用户管理',
          isFresh: false,
        }
      },{
        path: '/user/summary',
        name: 'user/summary',
        component: () => import( '../views/user/summary.vue'),
        meta: {
          title: '用户资产',
          isFresh: false,
        }
      },{
        path: '/user/ask/log',
        name: 'user/ask/log',
        component: () => import( '../views/user/askLog.vue'),
        meta: {
          title: '用户提问记录',
          isFresh: false,
        }
      },{
        path: '/user/invite',
        name: 'user/invite',
        component: () => import( '../views/user/invite.vue'),
        meta: {
          title: '用户邀请记录',
          isFresh: false,
        }
      },{
        path: '/user/coupon',
        name: 'user/coupon',
        component: () => import( '../views/shop/user/couponV1.vue'),
        meta: {
          title: '用户优惠券',
          isFresh: false,
        }
      },{
        path: '/shop/brand',
        name: 'shop/brand',
        component: () => import( '../views/shop/shopBrand.vue'),
        meta: {
          title: '商品品牌',
          isFresh: false,
        }
      },{
        path: '/shop/goods/type',
        name: 'shop/goods/type',
        component: () => import( '../views/shop/goodsType.vue'),
        meta: {
          title: '商品类型',
          isFresh: false,
        }
      },{
        path: '/shop/goods',
        name: 'shop/goods',
        component: () => import( '../views/shop/goods/goods.vue'),
        meta: {
          title: '商品列表',
          isFresh: false,
        }
      },{
        path: '/shop/goods/info',
        name: 'shop/goods/info',
        component: () => import( '../views/shop/goods/info.vue'),
        meta: {
          title: '商品详情',
          isFresh: false,
        }
      },{
        path: '/shop/goods/spec',
        name: 'shop/goods/spec',
        component: () => import( '../views/shop/goods/spec.vue'),
        meta: {
          title: '商品规格',
          isFresh: false,
        }
      },{
        path: '/shop/coupon/config/v1',
        name: 'shop/coupon/config/v1',
        component: () => import( '../views/shop/coupon/couponConfigV1/couponConfigV1.vue'),
        meta: {
          title: '优惠券配置',
          isFresh: false,
        }
      },{
        path: '/shop/coupon/config/v1/info',
        name: 'shop/coupon/config/v1/info',
        component: () => import( '../views/shop/coupon/couponConfigV1/info.vue'),
        meta: {
          title: '优惠券配置信息',
          isFresh: false,
        }
      },{
        path: '/shop/coupon/v1',
        name: 'shop/coupon/v1',
        component: () => import( '../views/shop/coupon/couponV1/couponV1.vue'),
        meta: {
          title: '优惠券列表',
          isFresh: false,
        }
      },{
        path: '/shop/coupon/v1/info',
        name: 'shop/coupon/v1/info',
        component: () => import( '../views/shop/coupon/couponV1/info.vue'),
        meta: {
          title: '优惠券信息',
          isFresh: false,
        }
      },{
        path: '/shop/order',
        name: 'shop/order',
        component: () => import( '../views/shop/order/order.vue'),
        meta: {
          title: '订单管理',
          isFresh: false,
        }
      },{
        path: '/shop/order/info',
        name: 'shop/order/info',
        component: () => import( '../views/shop/order/info.vue'),
        meta: {
          title: '订单信息',
          isFresh: false,
        }
      },{
        path: '/cms/apply',
        name: 'cms/apply',
        component: () => import( '../views/cms/apply.vue'),
        meta: {
          title: '报名数据',
          isFresh: false,
        }
      },{
        path: '/cms/article',
        name: 'cms/article',
        component: () => import( '../views/cms/article.vue'),
        meta: {
          title: '文章管理',
          isFresh: false,
        }
      },{
        path: '/cms/article/info',
        name: 'cms/article/info',
        component: () => import( '../views/cms/articleInfo.vue'),
        meta: {
          title: '文章信息',
          isFresh: false,
        }
      },{
        path: '/aigc/release',
        name: 'aigc/release',
        component: () => import( '../views/aigc/release.vue'),
        meta: {
          title: '发布软文订单',
          isFresh: false,
        }
      },{
        path: '/aigc/recharge/order',
        name: 'aigc/recharge/order',
        component: () => import( '../views/aigc/rechargeOrder.vue'),
        meta: {
          title: '发布软文订单',
          isFresh: false,
        }
      },{
        path: '/engprac/subject',
        name: 'engprac/subject',
        component: () => import( '../views/engprac/subject.vue'),
        meta: {
          title: '场景管理',
          isFresh: false,
        }
      },{
        path: '/engprac/level',
        name: 'engprac/level',
        component: () => import( '../views/engprac/level.vue'),
        meta: {
          title: '等级管理',
          isFresh: false,
        }
      },{
        path: '/engprac/role',
        name: 'engprac/role',
        component: () => import( '../views/engprac/role.vue'),
        meta: {
          title: '角色管理',
          isFresh: false,
        }
      },{
        path: '/engprac/excuse',
        name: 'engprac/excuse',
        component: () => import( '../views/engprac/excuse.vue'),
        meta: {
          title: '英语练习管理',
          isFresh: false,
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/common/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  // @ts-ignore
  return originalPush.call(this, location).catch(err => err)
}

export default router
