module.exports={
    base_url:'',
    dev_url:'',
    // test_url:'http://localhost:3083',
    // test_url:'http://chatgptapi-dev.genkigo.net',
    test_url:'http://consoleapi.chat.genkigo.net',
    release_url:'http://consoleapi.chat.genkigo.net',
    // release_url:'',
    // upload_url:'http://chatgptapi-dev.genkigo.net/common/uploadFile',
    upload_url:'https://chatgptapi.genkigo.net/common/uploadFile',
    // download_url:'http://localhost:3083',
    //  download_url:'http://chatgptapi-dev.genkigo.net',
     download_url:'https://chatgptapi.genkigo.net',
    // website_url:'http://aipro-dev.genkigo.net',
    website_url:'https://www.aigcplus.pro',
    // upload_url:'http://localhost:3083/common/uploadFile',
    // test_url:'http://consoleapi-dev.genkigo.net',
    upload:{
        pic_ext:'png,jpeg,jpg,gif',
        // pic_size:'4194304',
        pic_size:'10485760',
        video_ext:'mp4,rmvb,avi,mkv',
        video_size:'20971520',
        threeD:'obj,mtl,png,jpeg,jpg',
    },
    exclude_path:[ //额外路由 不校验用户登陆
        '/'
        ,'/login'
        ,'/register'
        ,'/home'
        ,'/articles'
        ,'/forget'
    ],

}