import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index'
import element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueClipboard from 'vue-clipboard2'
import '@/assets/css/base.css'
import '@/assets/css/style.css'
import config from '@/utils/config'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import {
  uploadFile,
  checkPower,
  formatDate,
  deepClone,
  jumpUrl,
  goBack,
  copyStr,
  copyError,
  copySuccess
} from "./utils/utils";
import {get, post} from "./utils/http";
import cache from "./utils/cache";

const utils = require("./utils/utils");
Vue.prototype.$funcs = {uploadFile, checkPower, formatDate, deepClone, copyStr, copyError, copySuccess};
Vue.config.productionTip = false;
Vue.prototype.$cache = cache;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$utils = utils;

Vue.use(element);
Vue.use(VueClipboard);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
